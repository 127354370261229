import React from "react";
import { withRouter } from "react-router";

import Layout from "./layout";

class Home extends React.Component {

    render() {
        return (
            <Layout>
                Still to develop
            </Layout>
        );
    }
}

export default withRouter(Home);